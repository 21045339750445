import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
    background: #ffffff;
    height: 100%;
    margin: 0;
  }

`
