import gql from "graphql-tag"

export const SHARE_AND_EARN_ADVOCATE_DETAILS_BY_ID = gql`
  query shareAndEarnMicrosite($advocateId: ID!) {
    shareAndEarnMicrosite {
      advocate(advocateId: $advocateId) {
        id
        firstName
        isValid
      }
    }
  }
`
