import { createInstance, enums, OptimizelyProvider } from '@optimizely/react-sdk'
import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { config, isProduction } from '../../utils/environment'
import { Analytics } from 'core'
import { useAdvocateId } from 'core/state'
import { v4 as uuidv4 } from 'uuid'

export interface IOptimizelyDecisionInfo {
  type: 'feature' | 'ab-test' | 'feature-test' | 'feature-variable'
  decisionInfo: { [key: string]: string }
  userId: string
  attributes: { [key: string]: string }
}

const optimizely = createInstance({
  sdkKey: config.optimizelySDK,
  errorHandler: {
    handleError: (error) => Sentry.captureException({ error, service: 'optimizely' }),
  },
  datafileOptions: {
    autoUpdate: true,
  },
  logger: isProduction()
    ? {
        log: () => null,
      }
    : undefined,
})

export const OptimizelyWrapper: React.FC = ({ children }) => {
  const advocateId = useAdvocateId()
  useEffect(() => {
    optimizely.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      onDecision,
    )
    return () => optimizely.notificationCenter.clearAllNotificationListeners()
  }, [])

  const onDecision = (decisionObject: IOptimizelyDecisionInfo) => {
    const eventBody = { ...decisionObject.decisionInfo, decisionType: decisionObject.type }

    switch (decisionObject.type) {
      case 'feature':
        Analytics.optimizely.feature(eventBody)
        break
      case 'ab-test':
        Analytics.optimizely.abTest(eventBody)
        break
      case 'feature-test':
        Analytics.optimizely.featureTest(eventBody)
        break
      case 'feature-variable':
        Analytics.optimizely.featureVariable(eventBody)
        break
    }
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: advocateId ? advocateId : uuidv4(),
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}
