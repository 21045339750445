import { Analytics } from 'core'
import { useAdvocateId } from 'core/state'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericErrorScreen } from 'shared'

const trackError = ({
  advocateId,
  errorCode,
}: {
  advocateId?: string
  errorCode: 'invalid_route' | 'other'
}) =>
  Analytics.track('Error Page', {
    errorCode,
    ...(advocateId ? { advocateId } : {}),
  })

export const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const advocateId = useAdvocateId()
  useEffect(() => trackError({ advocateId, errorCode: 'invalid_route' }), [advocateId])
  return <GenericErrorScreen title={t('errorScreen.header')} />
}

export const UnknownError: React.FC = () => {
  const advocateId = useAdvocateId()
  useEffect(() => trackError({ advocateId, errorCode: 'other' }), [advocateId])
  return <GenericErrorScreen />
}
