import gql from 'graphql-tag'
export const GET_SHARE_EARN_INCENTIVE_AMOUNT = gql`
  query getShareEarnIncentiveAmount {
    allShare_earns {
      edges {
        node {
          offer_details {
            incentive_amount
          }
        }
      }
    }
  }
`
