import { Text, Button, ButtonTypeEnum, FlexColumnLayout } from '@northone/ui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Image } from 'rebass'

import { Analytics } from 'core/analytics/actions'
import { useAdvocateId } from 'core/state'
import { BaseScreen, BaseLayout } from 'shared'
import { theme } from 'theme/theme'

import IconError from 'assets/images/IconError.png'
import { openUrl } from 'utils'

const refreshPage = (advocateId: string) => {
  openUrl(window.location.origin + (advocateId ? `/${advocateId}` : ''))
}

const contactSupport = (advocateId: string) => {
  Analytics.track('Contact Support Click', advocateId ? { advocateId } : undefined)
  openUrl('mailto:support@northone.com', { newTab: true })
}

export const GenericErrorScreen: React.FC<{
  title?: string
  subtitle?: string
  customButton?: JSX.Element
  disableCustomerCare?: boolean
}> = ({ title, subtitle, customButton, disableCustomerCare = false }) => {
  const advocateId = useAdvocateId()

  const { t } = useTranslation()

  useEffect(() => {
    window.Intercom('hide')
  }, [])

  return (
    <BaseLayout withHeroImage={false} isCenterContent>
      <BaseScreen isCenterContent>
        <FlexColumnLayout
          sx={{
            alignItems: ['center', 'flex-start'],
          }}
        >
          <Image
            sx={{ width: 70, margin: 'auto', marginBottom: 40, marginTop: [90, 5, 5] }}
            src={IconError}
          />
          <Text
            tag={'h3'}
            sx={{
              width: [360, 360, 449],
              textAlign: ['center'],
              marginBottom: [20, 41],
              color: theme.colors.ui1,
            }}
          >
            {title || t('errorScreen.header')}
          </Text>
          <Text
            tag={'body'}
            sx={{
              width: [360, 360, 449],
              textAlign: ['center'],
              marginBottom: [30, 41],
              color: theme.colors.ui2,
            }}
          >
            {subtitle || t('errorScreen.subTitle')}
          </Text>

          <Box
            sx={{
              marginTop: [0, 50, 50],
              width: ['100%', '223px'],
              margin: 'auto',
              position: ['fixed', 'relative'],
              bottom: ['15px', '0px'],
              paddingLeft: [10, 0, 0],
              paddingRight: [10, 0, 0],
            }}
          >
            {customButton ? (
              customButton
            ) : (
              <Button
                type={ButtonTypeEnum.PRIMARY_BLUE}
                sx={{
                  width: ['100%', '220px'],
                  margin: 'auto',
                  marginBottom: [10, 15],
                  height: 48,
                }}
                onClick={() => refreshPage(advocateId)}
              >
                {t('errorScreen.refreshPage')}
              </Button>
            )}
            {!disableCustomerCare ? (
              <Button
                type={ButtonTypeEnum.TERTIARY}
                sx={{
                  width: ['100%', '220px'],
                  background: '#F0F3F6',
                  margin: 'auto',
                  height: 48,
                }}
                onClick={() => contactSupport(advocateId)}
              >
                <span style={{ color: '#272F36' }}>{t('errorScreen.contactCustomerCare')}</span>
              </Button>
            ) : null}
          </Box>
        </FlexColumnLayout>
      </BaseScreen>
    </BaseLayout>
  )
}
