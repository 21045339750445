import { theme } from 'theme/theme'
import { config } from '../../utils/environment'

interface IIntercomSettings {
  app_id?: string | undefined
  alignment?: string
  horizontal_padding?: number
  vertical_padding?: number
  background_color?: string
  action_color?: string
  hide_default_launcher?: boolean
}

declare global {
  interface Window {
    Intercom(update: string, params?: IIntercomSettings): void
    intercomSettings: IIntercomSettings
  }
}

export const unauthenticatedUserIntercomSettings = {
  app_id: config.intercomAppId,
  alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 20,
  background_color: theme.colors.gold60,
  action_color: theme.colors.gold80,
}

export const displayIntercomWidget = (toDisplay: boolean) => {
  if (window.Intercom) {
    window.Intercom('update', {
      hide_default_launcher: !toDisplay,
    })
  }
}
