export interface IRootState {
  email: string
  phoneNumber: string
  advocateId: string
  advocateSource?: string
}

export const initialState: IRootState = {
  email: '',
  phoneNumber: '',
  advocateId: '',
  advocateSource: undefined,
}

type ActionTypes =
  | 'SET_PHONE_NUMBER'
  | 'SET_EMAIL'
  | 'SET_CAMPAIGN_ID'
  | 'SET_ADVOCATE_ID'
  | 'SET_ADVOCATE_SOURCE'

export interface Action<T = any> {
  type: T
}

export interface IAction<P> extends Action<ActionTypes> {
  payload?: P
}

export const reducer = (state: IRootState, action: IAction<any>): IRootState => {
  switch (action.type) {
    case 'SET_PHONE_NUMBER':
      return { ...state, phoneNumber: action.payload }
    case 'SET_EMAIL':
      return { ...state, email: action.payload }
    case 'SET_ADVOCATE_ID':
      return { ...state, advocateId: action.payload }
    case 'SET_ADVOCATE_SOURCE':
      return { ...state, advocateSource: action.payload }
    default:
      return state
  }
}
