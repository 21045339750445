import env, { IOptionalVariable } from 'env-var'
import pkg from '../../package.json'

/* tslint:disable no-var-requires */
require('dotenv').config()

export const isProduction = () => process.env.NODE_ENV === 'production'

export const RELEASE = pkg ? `${pkg.name}@${pkg.version}` : null
export const APP_CLIENT_NAME = pkg?.name

const stage = env
  .get('REACT_APP_STAGE')
  .default('prod')
  .required()
  .asEnum(['dev', 'prod', 'staging'])

/**
 * Wrapper function for env-var to inject default
 * values only for development environments
 *
 * @param variableName Variable name to get from process.env
 * @param defaultValue Value to use as default during development
 * @returns The resulting env-var chain
 */
const envWithDevDefault = (variableName: string, defaultValue: string): IOptionalVariable => {
  const value = env.get(variableName)
  return isProduction() ? value : value.default(defaultValue)
}

export const config = {
  stage,
  env: env
    .get('NODE_ENV')
    .default('development')
    .required()
    .asEnum(['production', 'development', 'test']),
  segmentWriteKey: envWithDevDefault(
    'REACT_APP_SEGMENT_KEY',
    'O0V6h5uUsGG3hQUF9pBMx48AWO3l73W2',
  ).asString(),
  graphQlUri: envWithDevDefault(
    'REACT_APP_GQL_URI',
    'https://dobby-graphql.dev.aws.northoneapps.com/',
  )
    .required()
    .asString(),
  sentryDsn: env.get('REACT_APP_SENTRY_DSN').asString(),
  auth0: {
    domain: envWithDevDefault('REACT_APP_AUTH0_DOMAIN', 'auth.northoneapps.com')
      .required()
      .asString(),
    clientId: envWithDevDefault('REACT_APP_AUTH0_CLIENT_ID', 'FglWlpb6i5WNSdzsiobsEWkSbXvnuwL7')
      .required()
      .asString(),
  },
  webOnboardingUri: envWithDevDefault(
    'REACT_APP_WEB_ONBOARDING_URI',
    'https://dumpling-onboarding.dev.aws.northoneapps.com/',
  )
    .required()
    .asString(),
  branchKey: envWithDevDefault(
    'REACT_APP_BRANCH_SDK_KEY',
    'key_test_apSiHi6Yk6q9FLztShV4ekedwsjGUVmU',
  )
    .required()
    .asString(),
  intercomAppId: envWithDevDefault('REACT_APP_INTERCOM_APP_ID', 'ua4yzghj').asString(),
  optimizelySDK: envWithDevDefault(
    'REACT_APP_OPTIMIZELY_SDK_KEY',
    'DQp7L19gzfmDxWKhnZtNaT',
  ).asString(),
  prismic: {
    uri: envWithDevDefault('REACT_APP_SHARE_AND_EARN_PRISMIC_URI', 'https://northone-campaigns-dev.prismic.io/graphql')
      .required()
      .asString(),
    accessToken: envWithDevDefault('REACT_APP_SHARE_AND_EARN_PRISMIC_TOKEN', 'MC5ZeXluRUJJQUFDQUFLOERD.77-977-9Ku-_vUxPTj0eFjvvv71cKlUq77-977-977-977-977-977-977-9S2Hvv73vv73vv73vv70Z77-977-9')
      .required()
      .asString(),
  },
}
