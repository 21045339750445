import React from 'react'
import { Box, Image, SxStyleProp } from 'rebass'
import { theme } from 'theme/theme'
import northOneLogo from 'assets/images/NorthOne-Color.svg'
import HeroImage from 'assets/images/PhoneWithCard2.png'
interface IProps {
  withHeroImage?: boolean
  withGoldBackground?: boolean
  navBarStyle?: SxStyleProp
  containerSx?: SxStyleProp
  isCenterContent?: boolean
}

export const BaseLayout: React.FC<IProps> = ({
  withGoldBackground,
  withHeroImage = true,
  navBarStyle,
  children,
  containerSx,
  isCenterContent,
}) => (
  <Box
    sx={{
      display: isCenterContent ? 'block' : 'grid',
      gridTemplateColumns: 'repeat(14, 1fr)',
      gridTemplateRows: 'repeat(7, 1fr)',
      overflowY: 'scroll',
      height: '100vh',
      backgroundColor: withGoldBackground ? theme.colors.gold10 : theme.colors.ui7,
      ...containerSx,
    }}
  >
    <Box
      sx={{
        gridRowStart: 1,
        gridColumnStart: isCenterContent ? [2, 2, 2, 2] : [2, 2, 2, 3],
        gridColumnEnd: isCenterContent ? [14, 14, 0] : [14, 14, 10],
        marginTop: 35,
        alignItems: 'start',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      {isCenterContent ? (
        <Image
          sx={{ margin: 'auto', width: 210, maxWidth: [152, 152, 187], ...navBarStyle }}
          src={northOneLogo}
        />
      ) : (
        <Image sx={{ maxWidth: [152, 152, 187], ...navBarStyle }} src={northOneLogo} />
      )}
      {withHeroImage && (
        <Image sx={{ maxWidth: [282, 0], alignSelf: 'center', marginTop: 36 }} src={HeroImage} />
      )}
    </Box>
    <Box
      sx={{
        gridColumnStart: [2, 2, 2, 3],
        gridColumnEnd: [14, 14, withHeroImage ? 9 : 14],
        gridRowStart: 3,
        marginTop: [20, 20, 50],
        paddingBottom: theme.spacing.verticalElementSpacing,
      }}
    >
      {children}
    </Box>
    {withHeroImage && (
      <Box
        sx={{
          gridColumnStart: 9,
          gridColumnEnd: 15,
          gridRowStart: 1,
          gridRowEnd: 9,
          bg: theme.colors.gold100,
          paddingHorizontal: 100,
          display: ['none', 'none', 'flex', 'flex'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image sx={{ maxWidth: [282, 300, 489] }} src={HeroImage} />
      </Box>
    )}
  </Box>
)
