import gql from 'graphql-tag'

export const REGISTER_USER_AND_ACCEPT_OFFER = gql`
  mutation shareAndEarnUserRegisterAndOfferAccept(
    $phone: String!
    $advocateId: ID!
    $advocateSource: String
  ) {
    shareAndEarnUserRegisterAndOfferAccept(
      phone: $phone
      advocateId: $advocateId
      advocateSource: $advocateSource
    ) {
      success
      message
      user {
        id
        businessId
      }
    }
  }
`
