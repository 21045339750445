import { ApolloClient, InMemoryCache, from, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import util from 'util'
import { osName, osVersion, mobileModel, browserName, browserVersion } from 'react-device-detect'
import { isProduction, config, APP_CLIENT_NAME, RELEASE } from '../../utils/environment'
import { auth } from 'core'
import { logger } from 'utils'
import { traceId } from 'core/error-logging'

const cache = new InMemoryCache()

const withTokenLink = setContext(async (_request, context) => {
  if (context.accessToken) {
    if (auth.validateAccessToken(context.accessToken)) {
      return { accessToken: context.accessToken }
    }
  }
  return null
})

/**
 * runs before every apollo client request
 */
const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = operation.getContext().accessToken

  operation.setContext({
    headers: {
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      client: APP_CLIENT_NAME,
      os_name: osName,
      os_version: `${osName} ${osVersion}`,
      ...(mobileModel !== 'none' && { device_model: mobileModel }),
      browser: `${browserName} ${browserVersion}`,
      trace_id: traceId,
    },
  })

  return forward ? forward(operation) : null
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationString = operation?.query?.loc?.source?.body
  const operationVariables = isProduction() ? {} : operation.variables

  Sentry.configureScope((scope) => {
    scope.setTags({
      operation: operation.operationName,
      service: `GQL`,
      trace_id: traceId,
      client: APP_CLIENT_NAME,
    })

    scope.setExtras({
      ...(operationString && { operationString }),
      ...(operationVariables && { operationVariables }),
    })
  })

  if (networkError) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        error: networkError,
      })
      Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - Network Error`)
    })
    if (!isProduction()) {
      logger.error(`[Network error]: ${networkError}`)
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (!isProduction()) {
        logger.error(error)
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: util.inspect(error, { depth: 10 }),
          trace_id: traceId,
        })
        Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - ${error.message}`)
      })
    })
  }
})

const httpLink = new HttpLink({
  credentials: 'same-origin',
  fetch,
  uri: config.graphQlUri,
})

const link = from([withTokenLink, authMiddleware, errorLink, httpLink])

export const createClient = () => {
  return new ApolloClient({
    cache,
    link,
    name: APP_CLIENT_NAME,
    version: RELEASE || '',
    connectToDevTools: !isProduction(),
  })
}
