import { _sessionStorage } from 'core/session-storage'
import React, { createContext, useEffect, useReducer } from 'react'
import { reducer, initialState, IRootState, IAction } from './reducer'

export const ProviderContext = createContext<{
  state: IRootState
  dispatch: React.Dispatch<IAction<any>>
}>({
  state: initialState,
  dispatch: () => {
    //
  },
})

const initializer = (initialValue = initialState) => {
  const persistedState = _sessionStorage.getItem('@store')
  return persistedState ? JSON.parse(persistedState) : initialValue
}
export const Provider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer)

  useEffect(() => {
    _sessionStorage.setItem('@store', JSON.stringify(state))
  }, [state])

  return <ProviderContext.Provider value={{ state, dispatch }}>{children}</ProviderContext.Provider>
}
