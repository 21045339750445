import React from 'react'
import { Box } from 'rebass'
import { theme } from 'theme/theme'
import { FlexColumnLayout, Text, TTextTag } from '@northone/ui'
export interface IBaseScreenProps {
  title?: string
  subtitle?: string
  subtitleTag?: TTextTag
  isCenterContent?: boolean
  buttonDisabled?: boolean
  customHeader?: React.ReactNode
  button?: JSX.Element
  footer?: React.ReactNode
}

export const BaseScreen: React.FC<IBaseScreenProps> = ({
  title,
  subtitle,
  isCenterContent,
  children,
  customHeader,
  subtitleTag,
  button,
  footer,
}) => {
  return (
    <>
      {customHeader ? (
        customHeader
      ) : (
        <FlexColumnLayout
          sx={{
            width: '100%',
            maxWidth: '452px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: ['center', 'flex-start'],
          }}
        >
          <Text
            tag={'h3'}
            sx={{
              textAlign: ['center', 'left'],
              marginBottom: [12, 21],
              color: theme.colors.ui1,
            }}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              tag={subtitleTag || 'body'}
              sx={{
                width: [281, 281, 349],
                textAlign: ['center', 'left'],
                color: theme.colors.ui2,
              }}
            >
              {subtitle}
            </Text>
          )}
        </FlexColumnLayout>
      )}
      <Box
        sx={{
          maxWidth: '452px',
          margin: isCenterContent ? 'auto' : 0,
        }}
      >
        {children}
        {button}
        <Box
          sx={{
            display: 'flex',
            justifyContent: ['center', 'left', 'left', 'left'],
            marginTop: '16px',
          }}
        >
          {footer}
        </Box>
      </Box>
    </>
  )
}
