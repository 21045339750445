import { useContext } from 'react'
import { ProviderContext } from './provider'

export const useAdvocateId = () => {
  const {
    state: { advocateId },
  } = useContext(ProviderContext)

  return advocateId
}
