import React, { useContext, useEffect } from 'react'
import { auth } from 'core'
import { useHistory } from 'react-router-dom'
import { RoutesEnum } from 'app'
import { Analytics } from 'core/analytics/actions'
import { FullScreenLoading } from '@northone/ui'
import { ProviderContext } from 'core/state'
import { config, openUrl, regex } from 'utils'

export const AuthLoginRedirect: React.FC<{}> = () => {
  const history = useHistory()
  const formatPhoneNumberForGQL = (phone: string) => phone.replace(regex.notNumber, '')

  const {
    state: { phoneNumber: phone, email, advocateId, advocateSource },
  } = useContext(ProviderContext)

  useEffect(() => {
    const handleLoginRedirect = async () => {
      const parsedURI = await auth.parseRedirectURI()

      const accessToken = parsedURI.result?.accessToken
      const error = parsedURI.error

      if (error) {
        return history.push(RoutesEnum.ERROR)
      }

      if (accessToken) {
        try {
          const response = await auth.registerUserAndAcceptOffer({
            phone: formatPhoneNumberForGQL(phone),
            advocateId,
            accessToken,
            advocateSource,
          })

          if (!response.success) {
            return history.push(RoutesEnum.ERROR)
          }

          Analytics.alias(response.user.id, () => {
            Analytics.identify(response.user.id, {
              email,
              phone,
              businessId: response.user.businessId,
            })
          })

          return openUrl(config.webOnboardingUri)
        } catch (e) {
          return history.push(RoutesEnum.ERROR)
        }
      }
    }
    handleLoginRedirect()
  })

  return <FullScreenLoading />
}
