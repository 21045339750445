import { landing, errorScreen } from './features'
import { button, inputFields, error } from './shared'
/**
 * Translations for English
 */

export const en = {
  landing,
  inputFields,
  button,
  errorScreen,
  error,
}
