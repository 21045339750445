import { regex } from "./regex"
import phone from "phone"

export const validate = {
  email: (email: string): boolean => regex.email.test(email),
  password: (password: string): boolean => regex.password.test(password),
  passwordLiveValidation: {
    characters: (password: string): boolean => regex.passwordValidation.characters.test(password),
    uppercase: (password: string): boolean => regex.passwordValidation.uppercase.test(password),
    lowercase: (password: string): boolean => regex.passwordValidation.lowercase.test(password),
    specialCharacter: (password: string): boolean =>
      regex.passwordValidation.specialCharacter.test(password),
  },
  phoneNumber: (phoneNumber: string) => {
    const cleanPhoneNumber = phoneNumber.replace(regex.notNumber, "")
    return Boolean(cleanPhoneNumber.length === 10 && phone(cleanPhoneNumber).length)
  },
}
