import { FlexRowLayout, Text } from "@northone/ui"
import React from "react"
import { theme } from "theme/theme"
import { ValidatorCheckIcon } from "./validator-check-icon.component"
interface IPasswordLiveValidationProps {
  content: {
    text: string
    isValid: boolean
  }
}

export const PasswordLiveValidation: React.FC<IPasswordLiveValidationProps> = ({ content }) => (
  <FlexRowLayout sx={{ marginBottom: [0, 10], alignItems: "center" }}>
    <ValidatorCheckIcon isValid={content.isValid} />
    <Text
      hasPartialBolding
      tag={["label", "body-small"]}
      sx={{
        color: content.isValid ? theme.colors.ui1 : theme.colors.ui2,
        marginLeft: "8px",
      }}
    >
      {content.isValid ? `*${content.text}*` : content.text}
    </Text>
  </FlexRowLayout>
)
