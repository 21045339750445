import { useEffect, useState } from 'react'

export const openUrl = (url: string, { newTab = false }: { newTab?: boolean } = {}): void => {
  if (newTab) {
    window.open(url, '_blank')

    return
  }

  window.location.href = url
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    function _handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', _handleResize)
    return () => window.removeEventListener('resize', _handleResize)
  }, [])
  return windowDimensions
}

export const addSearchParamsToUrl = (url: string, queryStrings: Record<string, string>): string => {
  const result = new URL(url)

  for (const key in queryStrings) {
    if (key) {
      result.searchParams.append(key, queryStrings[key])
    }
  }

  return result.toString()
}
