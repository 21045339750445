import { analytics } from '@northone/segment-js'
import { IOptimizelyDecisionInfo } from 'shared/optimizely/optimizely-wrapper'
import { logger, isProduction } from 'utils'

export const analyticsClient = analytics()

export const Analytics = {
  reset: () => analyticsClient.reset(),
  track: (eventName: string, properties?: any) => {
    if (!isProduction()) {
      logger.info('Analytic Event:', eventName)
    }
    analyticsClient.track(`S&E Microsite - ${eventName}`, properties)
  },
  page: analyticsClient.page,
  alias: (userId: string, cb: () => void) => analyticsClient.alias(userId, cb),

  identify: (userId?: string, traits?: any) => {
    if (!isProduction) {
      logger.info('Analytic Identify:', traits)
    }
    analyticsClient.identify(userId, traits)
  },

  optimizely: {
    feature: (eventBody: { decisionType: IOptimizelyDecisionInfo['type'] }) =>
      analyticsClient.track('Optimizely Feature Flag Decision Result', eventBody),
    abTest: (eventBody: { decisionType: IOptimizelyDecisionInfo['type'] }) =>
      analyticsClient.track('Optimizely A/B Test Experiment Decision Result', eventBody),
    featureTest: (eventBody: { decisionType: IOptimizelyDecisionInfo['type'] }) =>
      analyticsClient.track('Optimizely Feature Test Experiment Decision Result', eventBody),
    featureVariable: (eventBody: { decisionType: IOptimizelyDecisionInfo['type'] }) =>
      analyticsClient.track('Optimizely Feature Variable Decision Result', eventBody),
  },
}
