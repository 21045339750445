import React from 'react'
import { Box } from 'rebass'
import { theme } from '@northone/ui'
import { useTranslation } from 'react-i18next'

const termsAndConditionsLink = 'https://www.northone.com/terms-of-service'
const privacyPolicyLink = 'https://www.northone.com/privacy-policy'
const referralProgramLink =
  'https://help.northone.com/en/articles/4228585-northone-referral-program-terms-conditions'

export const TermsAndConditionsText: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        color: theme.colors.ui2,
        textAlign: ['left'],
        width: ['100%', '435px', '435px', '435px'],
        fontFamily: 'Graphik',
        lineHeight: '12px',
        fontWeight: 'normal',
        fontSize: '10px',
        letterSpacing: '0.2px',
        textTransform: 'none',
      }}
    >
      <span>{t('landing.footer.text')} </span>
      <span>
        <LinkText link={termsAndConditionsLink}>{t('landing.footer.termsAndConditions')}</LinkText>
      </span>
      <span>{t('landing.footer.and')}</span>
      <span>
        <LinkText link={privacyPolicyLink}> {t('landing.footer.privacyPolicy')}</LinkText>
      </span>
      <span style={{ marginBottom: '10px', display: 'block', height: 10, width: 20 }}></span>
      {t('landing.footer.finePrintA')}
      <span style={{ display: 'block', width: 20 }}></span>
      {t('landing.footer.finePrintB')}
      <span> </span>
      <LinkText link={referralProgramLink}>{t('landing.footer.referralProgram')}</LinkText>
      <span style={{ marginBottom: '10px', display: 'block', height: 10, width: 20 }}></span>
      <span>{t('landing.footer.disclaimer.paragraph1')}</span>
      <span style={{ marginBottom: '10px', display: 'block', height: 10, width: 20 }}></span>
      <span style={{ fontWeight: 'bold' }}>{t('landing.footer.disclaimer.paragraph2_bold')}</span>
      <span style={{ display: 'block' }}>{t('landing.footer.disclaimer.paragraph3')}</span>
    </Box>
  )
}

interface ILinkText {
  link: string
}

const LinkText: React.FC<ILinkText> = ({ children, link }) => (
  <Box
    as={'span'}
    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
    onClick={() => window.open(link, '_blank')}
  >
    {children}
  </Box>
)
