import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'
import * as serviceWorker from './service-worker'
import './normalize.css'
import './i18n/i18n'
import { ApolloProvider } from '@apollo/client'
import { createClient } from 'core'
import * as Sentry from '@sentry/browser'
import { config, RELEASE } from 'utils'
import { Provider } from 'core/state'
import { ThemeProvider } from 'emotion-theming'
import { GlobalStyle } from 'global-style'
import { BrowserRouter } from 'react-router-dom'

import { theme } from './theme/theme'

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.stage,
  ...(RELEASE && { release: RELEASE }),
})

const client = createClient()

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
