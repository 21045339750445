import React, { useContext, useEffect, useState } from 'react'
import {
  BaseScreen,
  BaseLayout,
  ReferralHeading,
  PasswordLiveValidation,
  TermsAndConditionsText,
  GenericErrorScreen,
} from 'shared'
import { useLocation, useParams } from 'react-router-dom'
import { Button, ButtonTypeEnum, TextInput, PasswordInput, MaskTextInput } from '@northone/ui'
import { unauthenticatedUserIntercomSettings } from 'core/intercom/intercom-settings'
import { SHARE_AND_EARN_ADVOCATE_DETAILS_BY_ID } from './gql/operations.gql'
import { shareAndEarnMicrosite, shareAndEarnMicrositeVariables } from './gql/types-gql'
import { Analytics } from 'core/analytics/actions'
import { useTranslation } from 'react-i18next'
import { config, openUrl, validate } from 'utils'
import { Box } from 'rebass'
import { auth } from 'core'
import { ProviderContext } from 'core/state'
import { useQuery } from '@apollo/client'
import { useFeature } from '@optimizely/react-sdk'
import { useShareAndEarnRewardAmount } from 'utils/prismic/helpers'

export const Loading: React.FC = () => (
  <BaseLayout withHeroImage={false} isCenterContent>
    <Box
      sx={{
        textAlign: 'center',
        fontSize: '20px',
        marginTop: 150,
      }}
    >
      Loading...
    </Box>
  </BaseLayout>
)

const GoToNorthone: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Button
      type={ButtonTypeEnum.PRIMARY_BLUE}
      sx={{
        width: ['100%', '223px'],
        margin: 'auto',
        height: 48,
        marginBottom: 10,
      }}
      onClick={() => openUrl('https://www.northone.com/')}
    >
      {t('landing.advocateError.goToNorthone')}
    </Button>
  )
}

const routeToOnboardingIfAuthenticated = async () => {
  if (await auth.isAuthenticated()) {
    openUrl(config.webOnboardingUri)
  }
}

const useRedirectToNotFound = () => {
  // @ts-ignore: Optimizely variables not required but come before the clientReady variable
  const [isEnabled, _, optimizelyClientReady, didTimeout] = useFeature('se_advocate')
  return {
    isRedirectToNotFound: !isEnabled && optimizelyClientReady && !didTimeout,
  }
}
const useParamsQuery = () => new URLSearchParams(useLocation().search)

export const Landing: React.FC = () => {
  const { advocateId } = useParams<{ advocateId: string }>()
  const query = useParamsQuery()
  const source = query.get('source')

  const { t } = useTranslation()
  const { dispatch } = useContext(ProviderContext)
  const { isRedirectToNotFound } = useRedirectToNotFound()

  useEffect(() => {
    routeToOnboardingIfAuthenticated().then()
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_ADVOCATE_ID', payload: advocateId })
  }, [advocateId])

  useEffect(() => {
    if (!source) return
    dispatch({ type: 'SET_ADVOCATE_SOURCE', payload: source })
  }, [source])

  const { loading, data } = useQuery<shareAndEarnMicrosite, shareAndEarnMicrositeVariables>(
    SHARE_AND_EARN_ADVOCATE_DETAILS_BY_ID,
    {
      variables: { advocateId },
      fetchPolicy: 'network-only',
      skip: isRedirectToNotFound,
    },
  )

  if (isRedirectToNotFound) {
    return (
      <InvalidAdvocate
        advocateId={advocateId}
        reason={'feature_disabled'}
        disableCustomerCare={true}
      />
    )
  }

  const advocateExists = Boolean(data?.shareAndEarnMicrosite?.advocate?.id)
  const advocateIsValid = Boolean(data?.shareAndEarnMicrosite?.advocate?.isValid)

  if (loading) {
    return <Loading />
  }

  if (!advocateExists || !advocateIsValid) {
    return (
      <InvalidAdvocate
        advocateId={advocateId}
        reason={advocateExists ? 'advocate_disabled' : 'not_found'}
        disableCustomerCare={false}
      />
    )
  }

  return (
    <Form
      advocateFirstName={
        data?.shareAndEarnMicrosite?.advocate?.firstName ?? t('landing.header.fallbackName')
      }
    />
  )
}

export const Form: React.FC<{ advocateFirstName: string }> = ({ advocateFirstName }) => {
  const { advocateId } = useParams<{ advocateId: string }>()
  const {
    state: { phoneNumber, email },
    dispatch,
  } = useContext(ProviderContext)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>('')
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')

  const { t } = useTranslation()

  const { formattedRewardAmount, incentiveAmountLoading } = useShareAndEarnRewardAmount()

  const getHeaderProps = () => {
    return {
      advocateName: advocateFirstName || '$Advocate',
      headerText: formattedRewardAmount
        ? t('landing.header.title.prismic', { formattedRewardAmount })
        : t('landing.header.title.fallback'),
      subtitle: formattedRewardAmount
        ? t('landing.header.subtitle.prismic', { formattedRewardAmount })
        : t('landing.header.subtitle.fallback'),
    }
  }

  useEffect(() => {
    window.Intercom('boot', {
      ...unauthenticatedUserIntercomSettings,
    })
  }, [])

  useEffect(() => {
    Analytics.track('View Offer', { advocateId })
  }, [advocateId])

  const passwordLiveValidationContent = [
    {
      text: t('landing.liveValidation.characters'),
      isValid: validate.passwordLiveValidation.characters(password),
    },
    {
      text: t('landing.liveValidation.lowercase'),
      isValid: validate.passwordLiveValidation.lowercase(password),
    },

    {
      text: t('landing.liveValidation.match'),
      isValid: Boolean(password && passwordConfirm && password === passwordConfirm),
    },
    {
      text: t('landing.liveValidation.uppercase'),
      isValid: validate.passwordLiveValidation.uppercase(password),
    },
    {
      text: t('landing.liveValidation.specialCharacter'),
      isValid: validate.passwordLiveValidation.specialCharacter(password),
    },
  ]

  const isButtonDisabled = !Boolean(
    phoneNumber &&
      email &&
      password &&
      passwordConfirm &&
      password === passwordConfirm &&
      validate.passwordLiveValidation.characters(password) &&
      validate.passwordLiveValidation.lowercase(password) &&
      validate.passwordLiveValidation.uppercase(password) &&
      validate.passwordLiveValidation.specialCharacter(password),
  )

  if (incentiveAmountLoading) {
    return <Loading />
  }
  return (
    <BaseLayout withHeroImage={false}>
      <BaseScreen
        customHeader={<ReferralHeading {...getHeaderProps()} />}
        button={
          <Button
            type={ButtonTypeEnum.PRIMARY_BLUE}
            sx={{ marginTop: [19, 12], width: '100%' }}
            disabled={isButtonDisabled || loading}
            onClick={async () => {
              setLoading(true)
              const isEmailValid = validate.email(email)
              const isPhoneValid = validate.phoneNumber(phoneNumber)

              if (!isEmailValid) {
                setEmailErrorMessage(t('error.email'))
              }

              if (!isPhoneValid) {
                setPhoneErrorMessage(t('error.phone'))
              }

              if (isPhoneValid && isEmailValid) {
                try {
                  Analytics.track('Submit Click', { advocateId })
                  await auth.auth0SignUpAndLogin({
                    email,
                    password,
                  })
                } catch (e) {
                  setLoading(false)
                }
              }

              setLoading(false)
              return
            }}
          >
            {t('button.getStarted')}
          </Button>
        }
        footer={<TermsAndConditionsText />}
      >
        <MaskTextInput
          autoFocus
          autoComplete="tel"
          label={t('inputFields.phone')}
          placeholder={''}
          maskType={'PHONE_NUMBER'}
          value={phoneNumber}
          onChange={(payload) => {
            if (phoneErrorMessage) {
              setPhoneErrorMessage('')
            }
            dispatch({ type: 'SET_PHONE_NUMBER', payload })
          }}
          errorMessage={phoneErrorMessage}
        />
        <TextInput
          autoComplete={'email'}
          label={t('inputFields.email')}
          placeholder={''}
          value={email}
          onChange={(value) => {
            if (emailErrorMessage) {
              setEmailErrorMessage('')
            }
            dispatch({ type: 'SET_EMAIL', payload: value.toLowerCase() })
          }}
          errorMessage={emailErrorMessage}
          maxLength={50}
        />
        <PasswordInput
          autoComplete={'new-password'}
          label={t('inputFields.password')}
          placeholder={''}
          value={password}
          onChange={setPassword}
        />
        <PasswordInput
          autoComplete={'new-password'}
          label={t('inputFields.passwordConfirm')}
          placeholder={''}
          value={passwordConfirm}
          onChange={setPasswordConfirm}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            marginTop: '8px',
            marginBottom: [12, 0],
          }}
        >
          {passwordLiveValidationContent.map((content, idx) => (
            <PasswordLiveValidation key={idx} content={content} />
          ))}
        </Box>
      </BaseScreen>
    </BaseLayout>
  )
}

const InvalidAdvocate: React.FC<{
  advocateId: string
  reason: 'advocate_disabled' | 'not_found' | 'feature_disabled'
  disableCustomerCare?: boolean
}> = ({ advocateId, reason, disableCustomerCare }) => {
  const { t } = useTranslation()

  useEffect(() => {
    Analytics.track('Invalid Advocate Link', {
      advocateId,
      reason,
    })
  }, [advocateId, reason])

  return (
    <GenericErrorScreen
      title={t('landing.advocateError.title')}
      subtitle={t('landing.advocateError.subtitle')}
      customButton={<GoToNorthone />}
      disableCustomerCare={disableCustomerCare}
    />
  )
}
