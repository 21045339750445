import { ApolloClient, InMemoryCache } from '@apollo/client'
import { formatMoney } from 'accounting'
import { createPrismicLink } from 'apollo-link-prismic'
import { config } from 'utils'
import { useEffect, useState } from 'react'
import { GET_SHARE_EARN_INCENTIVE_AMOUNT } from './queries'
import { IAllShareEarnResponse } from './types'
import { Analytics } from 'core'
import { events } from 'core/analytics/events'

export const shareAndEarnClient = new ApolloClient({
  link: createPrismicLink({
    uri: config.prismic.uri,
    accessToken: config.prismic.accessToken,
  }),
  cache: new InMemoryCache({}),
})
export const getShareAndEarnIncentiveAmount = async () => {
  const response = await shareAndEarnClient.query<IAllShareEarnResponse>({
    query: GET_SHARE_EARN_INCENTIVE_AMOUNT,
  })
  const amount = response?.data?.allShare_earns.edges[0].node.offer_details[0].incentive_amount
  if (!amount) {
    if (response.error || response.errors) {
      Analytics.track(events.prismicError, { source: 'microsite' })
    }
    return
  }
  return amount.toString()
}
export const useShareAndEarnRewardAmount = () => {
  const [formattedRewardAmount, setRewardAmount] = useState<string | undefined>()
  const [incentiveAmountLoading, setIncentiveAmountLoading] = useState(true)
  useEffect(() => {
    getShareAndEarnIncentiveAmount()
      .then((amount) => {
        if (!amount) return
        setRewardAmount(formatMoney(amount, '$', 0))
      })
      .finally(() => {
        setIncentiveAmountLoading(false)
      })
  }, [])
  return { formattedRewardAmount, incentiveAmountLoading }
}
