import React from "react"
import { theme } from "theme/theme"

export const ValidatorCheckIcon = ({ isValid }: { isValid: boolean }): JSX.Element => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.94434" cy="8" r="8" fill={isValid ? theme.colors.blue100 : theme.colors.ui4} />
    <path
      d="M12.4995 5.33484L7.86796 10.6682L5.38843 7.9207"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
