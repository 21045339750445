type SessionStorageKeys = '@store'

class SessionStorageService {
  public clearStorage(): void {
    sessionStorage.clear()
  }

  public setItem(key: SessionStorageKeys, value: string) {
    sessionStorage.setItem(key, value)
  }
  public getItem(key: SessionStorageKeys): string | null {
    return sessionStorage.getItem(key)
  }
}

export const _sessionStorage = new SessionStorageService()
