export const landing = {
  liveValidation: {
    characters: '8 characters minimum',
    specialCharacter: '1 special character',
    match: 'Passwords match',
    uppercase: '1 uppercase character',
    lowercase: '1 lowercase character',
  },
  advocateError: {
    title: 'Looks like this link is\nno longer valid',
    subtitle: 'Check out northone.com to see how we make\nbusiness banking better.',
    goToNorthone: 'Go to northone.com',
  },
  header: {
    fallbackName: 'A NorthOne customer',
    title: {
      prismic: 'invited you to join NorthOne and earn {{formattedRewardAmount}}',
      fallback: 'invited you to join NorthOne',
    },
    subtitle: {
      prismic:
        "Open a NorthOne Account in as little as 3 minutes†. You'll earn a {{formattedRewardAmount}} credit when you make your first qualifying deposit††.",
      fallback:
        "Open a NorthOne Account in as little as 3 minutes†. You'll earn a credit when you make your first qualifying deposit††.",
    },
  },
  footer: {
    text: `By clicking on "Get started", you confirm you have read and agree to`,
    termsAndConditions: 'Terms & Conditions',
    and: ' and ',
    privacyPolicy: 'Privacy Policy.',
    finePrintA: '†Based on NorthOne user data.',
    finePrintB:
      '††A qualifying deposit is a deposit of at least $50. For more information, see the',
    referralProgram: 'Referral Program FAQs.',
    disclaimer: {
      paragraph1:
        'NorthOne is a financial technology company, not a bank. Banking Services provided by The Bancorp Bank, N.A., Member FDIC. The NorthOne Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A. pursuant to license by Mastercard International Incorporated and may be used everywhere Debit Mastercard is accepted. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.',
      paragraph2_bold: 'Important information about procedures for opening a new account:',
      paragraph3:
        'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents.',
    },
  },
}

export const errorScreen = {
  header: 'There was a technical problem',
  subTitle:
    'Try refreshing the page. If this doesn’t solve the problem, reach out to our Customer Care team.',
  refreshPage: 'Refresh page',
  troubles: 'Having some troubles?',
  contactCustomerCare: 'Contact Customer Care',
  notFound: "Oops! We couldn't find the page you were looking for.",
}
