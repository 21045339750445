import auth0, { Auth0Error, WebAuth } from 'auth0-js'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { logger, config } from 'utils'
import { REGISTER_USER_AND_ACCEPT_OFFER } from './operations.gql'
import { createClient } from 'core/apollo/client'
import { ApolloClient } from '@apollo/client'

interface IRegisterInput {
  phone: string
  advocateId: string
  accessToken: string
  advocateSource?: string
}

class AuthService {
  public auth0: WebAuth
  private readonly apolloClient: ApolloClient<any>

  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: config.auth0.domain,
      clientID: config.auth0.clientId,
      responseType: 'token id_token',
      audience: 'northoneCoreApi',
      redirectUri: window.location.origin + '/login-redirect',
    })
    this.apolloClient = createClient()
  }

  public async registerUserAndAcceptOffer(
    registerInput: IRegisterInput,
  ): Promise<{ success: boolean; user: { businessId: string; id: string } }> {
    const { advocateId, phone, advocateSource } = registerInput
    const response = await this.apolloClient.mutate({
      mutation: REGISTER_USER_AND_ACCEPT_OFFER,
      variables: { phone, advocateId, advocateSource },
      context: { accessToken: registerInput.accessToken },
    })

    return response.data?.shareAndEarnUserRegisterAndOfferAccept
  }

  /**
   * sign user up on auth0 and login (auth0 hits callbackUrl with token)
   */
  public async auth0SignUpAndLogin(credentials: { email: string; password: string }) {
    const trimmedEmail = credentials.email.trim()
    const params = { ...credentials, email: trimmedEmail }
    try {
      await this.auth0Signup(params)
      await this.auth0Login(params)
    } catch (e) {
      if ((e as Auth0Error).code === 'user_exists') {
        await this.auth0Login(params)
      }
    }
  }

  private async auth0Signup(credentials: { email: string; password: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.signup(
        {
          connection: 'Username-Password-Authentication',
          ...credentials,
        },
        (err, result) => {
          if (err) {
            return reject(err)
          }
          resolve(result)
        },
      )
    })
  }

  /**
   * logs in a user onAuth0 in and triggers a callback to the redirect URI with
   * #access_token & id_token as query params OR errors as query params
   * example url: http:localhost:3000/{this.loginRedirectURI}#access_token=eympsRnRM2NqU...
   */
  private async auth0Login(credentials: { email: string; password: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          ...credentials,
          scope: 'openid',
        },
        (err, result) => {
          logger.error('err', err)
          if (err) {
            return reject(err)
          }
          resolve(result)
        },
      )
    })
  }

  public async isAuthenticated(): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth0.checkSession({ scope: 'openid' }, (err, { accessToken } = {}) => {
        if (err) {
          return resolve(false)
        }

        resolve(Boolean(accessToken))
      })
    })
  }

  public validateAccessToken(token: string): boolean {
    if (!token) {
      return false
    }
    try {
      const decodedToken = jwtDecode(token) as any
      const currentTimestamp = moment().unix()
      return decodedToken.exp > currentTimestamp
    } catch (err) {
      logger.error(`Error decoding token: ${(err as Error).message || err}`, err)
      return false
    }
  }

  public parseRedirectURI(): Promise<{
    result: auth0.Auth0DecodedHash | null
    error: auth0.Auth0ParseHashError | null
  }> {
    return new Promise((resolve) => {
      this.auth0.parseHash((err, res) => {
        resolve({
          result: res,
          error: err,
        })
      })
    })
  }
}

export const auth = new AuthService()
