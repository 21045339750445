import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useLocation } from 'react-router'

import { Analytics } from 'core'
import { Landing, AuthLoginRedirect, NotFound, UnknownError } from 'features'
import './fonts/stylesheet.css'
import { OptimizelyWrapper } from 'shared/optimizely/optimizely-wrapper'

export enum RoutesEnum {
  LANDING = '/:advocateId',
  ERROR = '/troubleshoot',
  AUTH_LOGIN_REDIRECT = '/login-redirect',
}

export const App: React.FC = () => {
  const { pathname } = useLocation()
  const [isInitialLanding, setIsInitialLanding] = useState(true)

  useEffect(() => {
    if (!isInitialLanding || pathname.includes(RoutesEnum.AUTH_LOGIN_REDIRECT)) {
      return Analytics.page()
    }

    Analytics.page(
      {},
      {
        // append UTM params only on initial
        // landing to avoid long URLs
        context: {
          campaign: {
            medium: 'cpc',
            source: 'virality',
            name: 'share_and_earn',
          },
        },
      },
    )

    setIsInitialLanding(true)
  }, [pathname])

  return (
    <OptimizelyWrapper>
      <Switch>
        <Route path={RoutesEnum.ERROR} exact component={UnknownError} />
        <Route path={RoutesEnum.AUTH_LOGIN_REDIRECT} exact component={AuthLoginRedirect} />
        <Route path={RoutesEnum.LANDING} exact component={Landing} />
        <Route component={NotFound} />
      </Switch>
    </OptimizelyWrapper>
  )
}
