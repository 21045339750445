import { theme as N1Theme } from '@northone/ui'

export const MOBILE_WIDTH = 426

const { colors: N1Colors, ...restOfN1Theme } = N1Theme
export const theme = {
  ...restOfN1Theme,
  breakpoints: [`${MOBILE_WIDTH}px`, '834px', '1112px', '1280px', '1440px'],
  colors: {
    ...N1Colors,
  },
  spacing: {
    buttonRowToBody: 20,
    verticalElementSpacing: 50,
  },
}
