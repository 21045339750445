import React from 'react'
import { Box } from 'rebass'
import { Text } from '@northone/ui'
import { theme } from 'theme/theme'

interface IProps {
  advocateName: string
  headerText: string
  subtitle: string
}

export const ReferralHeading: React.FC<IProps> = ({ advocateName, subtitle, headerText }) => (
  <Box sx={{ marginY: [20, 20, 0], maxWidth: '613px' }}>
    <Text tag={'h3Serif'} sx={{ marginBottom: [22, 22, 12] }}>
      <Box as={'span'} color={theme.colors.gold100}>
        {advocateName}{' '}
      </Box>
      {headerText}
    </Text>
    <Text tag={'body-large'} color={theme.colors.ui2} sx={{ marginBottom: [25, 15] }}>
      {subtitle}
    </Text>
  </Box>
)
