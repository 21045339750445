import * as Sentry from "@sentry/browser"
import winston from "winston"
import Transport from "winston-transport"

import { isProduction } from "utils"

class SentryTransport extends Transport {
  public log(info: { level: string; message: string; meta?: any }, callback: () => void) {
    // Sentry levels can only be set to fatal, error, warning, info, or debug
    // https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browser
    const sentryLevel = info.level === "verbose" ? "debug" : info.level
    Sentry.addBreadcrumb({
      level: sentryLevel as Sentry.Severity,
      category: "console",
      message: info.message,
      ...(info.meta && { data: info.meta }),
    })

    if (callback) {
      callback()
    }
  }
}

export const logger = winston.createLogger({
  level: "info",
  format: winston.format.prettyPrint(),
})

if (isProduction()) {
  logger.add(new SentryTransport({ level: "info" }))
}

if (!isProduction()) {
  logger.add(new winston.transports.Console({ level: "verbose" }))
}
