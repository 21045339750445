export const inputFields = {
  phone: 'Phone number',
  email: 'Email address',
  password: 'Password',
  passwordConfirm: 'Confirm password',
}

export const button = {
  continue: 'Continue',
  getStarted: 'Get started',
  support: 'Contact support',
  sendLink: 'Send Link',
  linkSent: 'Link Sent!',
}

export const error = {
  phone: 'Enter a valid phone number',
  email: 'Enter a valid email',
}
