export const regex = {
  // eslint-disable-next-line
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  /*
password regex should match https://manage.auth0.com/#/connections/database/con_ZinHHEnw3Ia5irfV/security

Passwords will contain at least 1 upper case letter
Passwords will contain at least 1 lower case letter
Passwords will contain at least 1 number or special character
Passwords will contain at least 8 characters in length

password regex based on: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
*/
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
  passwordValidation: {
    characters: /^.{8,}$/,
    uppercase: /^.*[A-Z]/,
    lowercase: /^.*[a-z]/,
    specialCharacter: /^.*[!@#$%^&*]/,
  },
  notNumber: /[^0-9]/g,
}
